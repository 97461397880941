import React from 'react';
import { ProductDocumentationCards } from '../../../components/ProductDocumentationCards';
import { LayoutKometa } from '../../../layouts/LayoutKometa';

const KometaProductPage = () => (
  <LayoutKometa>
    <ProductDocumentationCards />
  </LayoutKometa>
);

export default KometaProductPage;
