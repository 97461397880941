import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { ProductCategoryCard } from './ProductCategoryCard';

export const ProductDocumentationCards = () => {
  const location = useLocation();

  return (
    <div className="grid gap-8 row-gap-5 sm:grid-cols-3">
      <Link
        aria-label="View Tailwind Config"
        title="View Tailwind Config"
        to={`${location.pathname}/config`}
      >
        <ProductCategoryCard
          title="Tailwind Config"
          icon={
            <svg
              viewBox="0 0 24 24"
              className="w-16"
              fill="none"
              stroke="currentColor"
              strokeWidth="1"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeLinejoin="round"
            >
              <line fill="none" x1="14" y1="4" x2="23" y2="4" />
              <line fill="none" x1="1" y1="4" x2="4" y2="4" />
              <line x1="21" y1="12" x2="23" y2="12" />
              <line x1="1" y1="12" x2="12" y2="12" />
              <line x1="14" y1="20" x2="23" y2="20" />
              <line x1="1" y1="20" x2="4" y2="20" />
              <circle cx="7" cy="4" r="3" />
              <circle cx="15" cy="12" r="3" />
              <circle cx="7" cy="20" r="3" />
            </svg>
          }
        >
          Custom color palette, fonts, spacings, variants, and others.
        </ProductCategoryCard>
      </Link>
      <Link
        aria-label="View Components"
        title="View Components"
        to={`${location.pathname}/components`}
      >
        <ProductCategoryCard
          title="Components"
          icon={
            <svg
              viewBox="0 0 24 24"
              className="w-16"
              fill="none"
              stroke="currentColor"
              strokeWidth="1"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeLinejoin="round"
            >
              <rect x="2" y="2" width="4" height="20" />
              <rect x="18" y="10" width="4" height="12" />
              <rect x="10" y="2" width="12" height="4" />
              <rect x="10" y="18" width="4" height="4" />
              <rect x="10" y="10" width="4" height="4" />
            </svg>
          }
        >
          Explore the components and simply copy the source code.
        </ProductCategoryCard>
      </Link>
      <Link
        aria-label="View FAQ and License"
        title="View FAQ and License"
        to={`${location.pathname}/faq`}
      >
        <ProductCategoryCard
          title="FAQ & License"
          icon={
            <svg
              viewBox="0 0 24 24"
              className="w-16"
              fill="none"
              stroke="currentColor"
              strokeWidth="1"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeLinejoin="round"
            >
              <path d="M8,19v1.5 C8,22.75,5.5,23,5.5,23s14.829,0,15,0c1.381,0,2.5-1.119,2.5-2.5V19H8z" />
              <path d="M20,16V1H3v19.5 C3,21.881,4.119,23,5.5,23H7" />
              <line x1="8" y1="6" x2="15" y2="6" />
              <line x1="8" y1="10" x2="15" y2="10" />
              <line x1="8" y1="14" x2="15" y2="14" />
            </svg>
          }
        >
          Answers to your questions and product license.
        </ProductCategoryCard>
      </Link>
    </div>
  );
};
