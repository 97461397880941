import React from 'react';

export const ProductCategoryCard = ({ children, icon, title }) => (
  <div className="h-full overflow-hidden transition-colors duration-300 border border-gray-400 rounded shadow-sm hover:border-indigo-600 group">
    <div className="flex items-center justify-center h-40 p-10 text-gray-500 transition-colors duration-300 bg-gray-200 group-hover:bg-indigo-100 group-hover:text-indigo-600">
      {icon}
    </div>
    <div className="px-5 py-4 transition-colors duration-300 border-t border-gray-400 group-hover:border-indigo-600">
      <div className="flex items-center justify-between mb-px">
        <header className="font-semibold transition-colors duration-300 xl:text-lg group-hover:text-indigo-700">
          {title}
        </header>
        <div>
          <svg
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
            className="w-5 text-gray-500 transition-colors duration-300 group-hover:text-indigo-600"
          >
            <line x1="2" y1="12" x2="22" y2="12" />
            <polyline points="15,5 22,12 15,19" />
          </svg>
        </div>
      </div>
      <p className="sm:text-sm xl:text-base">{children}</p>
    </div>
  </div>
);
